import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { useLocation } from "react-router-dom";
import { useAxios } from '../hooks/useAxios';
import Gallery from './LightGallery';
import { OrderItem } from './OrderItem/OrderItem';

const LessonsTable = () => {

    const hiddenEmailEl = document.querySelector('input#unica_UN');
    const emailId = hiddenEmailEl ? hiddenEmailEl.value.toLowerCase() : '';
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const testEmailId = email ? email : 'alicexu1227_2@aaxischina.com';
    const [isAutoRenew, setIsAutoRenew] = useState(false);

    const { response, loading, error } = useAxios({
        method: 'POST',
        baseURL: 'https://t0e0p8b8rk.execute-api.us-west-2.amazonaws.com/default/v1/lessons/details',
        headers: {
            // 'Content-Type': 'application/json;charset=UTF-8',
            //'X-API-Key': process.env.REACT_APP_LESSONS_X_API_KEY
            'X-API-Key': 'q6gbbkZXAy19dPcDugYO21U3KyPHQw6T7bdoLL4R'
        },
        data: {  // no need to stringify
            "persona": "student",
            "emailId": emailId
        }
    });


    const subScriptionRes = useAxios({
        method: 'GET',
        url: '/rest/model/ngp/commerce/order/scheduled/ScheduledOrderActor/getProfileScheduledOrder?posCustomerId=1010030500',
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    if (!Array.isArray(response)) return <p>No lesson data available, refresh the page to try again.</p>;

    const onRenewalClick = () => {
        setIsAutoRenew((preIsAutoRenew) => !preIsAutoRenew);
    }

    const convertOrderResToData = (item) => {

        const getName = () => {
            const subScriptionItem = item.items.filter(s => !s.productItemData.displayName.toLowerCase().includes('registration fee'));
            return subScriptionItem ? subScriptionItem[0].productItemData.displayName : '';
        }
        const orderProps = {
            name: getName(),
            orderNumber: item.initialOrderInfo.initialOrderInfo,
            orderStatus: item.state,
            price: 99,
            nextPaymentDate: item.nextScheduledRun,
            cardDetail: {
                cardType: item.myAccountSubsPaymentInfo.displayName,
                holderName: item.myAccountSubsPaymentInfo.ownerName,
                cardNumber: item.myAccountSubsPaymentInfo.maskedCardNumber,
                expiryDate: item.myAccountSubsPaymentInfo.expiredDateMsg
            },
        }
        return (<OrderItem
            {...orderProps}
            isAutoRenew={isAutoRenew}
            onRenewalClick={onRenewalClick} />)
    }



    return (
        <div className='my-lessons-container'>
            <div style={{ display: 'none', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                {!subScriptionRes.loading ? (subScriptionRes.response && subScriptionRes.response.result.scheduledOrders.map(s => (
                    convertOrderResToData(s)
                ))) :
                    <p>loading...</p>}
            </div>
            {loading ? (
                <p>loading...</p>
            ) : (
                <div>
                    {error && (
                        <div>
                            <p>{error.message}</p>
                        </div>
                    )}
                    <div>
                        {

                            // no need to use another state to store data, response is sufficient

                            response?.map((student, index) => {

                                let lessonObj = student.lessons[0],
                                    studentName = student.studentName ? student.studentName : '',
                                    studentId = student.studentId ? student.studentId : '',
                                    instructorId = lessonObj.instructorId ? lessonObj.instructorId : '',
                                    instrumentId = lessonObj.instrumentValue ? lessonObj.instrumentValue : '',
                                    studentLevel = lessonObj.studentLevel ? lessonObj.studentLevel : '';


                                const [studentFirstName, studentLastName] = studentName.split(' ');
                                let musicProdigyIframeSrc = 'https://app.musicprodigy.com/gc?' +
                                    '&studentId=' + studentId +
                                    '&teacherId=' + instructorId +
                                    '&instrument=' + instrumentId +
                                    '&levelID=' + studentLevel +
                                    '&isTeacher=0' +
                                    '&studentFirstName=' + studentFirstName +
                                    '&studentLastName=' + studentLastName;
                                return (
                                    <div className="container lessons-container py-5" key={index}>
                                        <Collapsible
                                            triggerClassName={'gl header short'}
                                            trigger={student.studentName}
                                            triggerTagName={'h2'}
                                            open={true}
                                            triggerOpenedClassName={'gl header short'}

                                        >
                                            <span class="lesson-type">{student.lessons[0].instrument} Lessons</span>


                                            <iframe id="dpx_frame" title="Music Prodigy" allowFullScreen allow="camera *;microphone *"
                                                className="table table-bordered table-hover my-lesson-schedule-table"
                                                src={musicProdigyIframeSrc}>

                                            </iframe>
                                            <Gallery />


                                        </Collapsible>

                                    </div>
                                )
                            })


                        }
                    </div>
                </div>
            )}

        </div>
    );
}

export default LessonsTable;