
import axios from 'axios';

export const Axios = axios.create({
    baseURL: `https://rst.guitarcenter.com/`,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
    timeout: 10000,
});

export default Axios;