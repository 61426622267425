import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';


function Gallery() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="lesson-video-gallery">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgVideo, lgThumbnail, lgZoom]}
            >


                            <a className="lesson-yt-video"
                                href="#"
                                data-lg-size="1280-720"
                                data-src="//www.youtube.com/watch?v=bBcxaVqYOvg&mute=0"
                                data-poster="https://img.youtube.com/vi/bBcxaVqYOvg/maxresdefault.jpg"
                                data-sub-html="<h4>Guitar Pick Tips | Guitar Center Lessons</h4><p>Finger Dexterity Exercises (Hammer-On, Pull-Off, Crabwalk, Alternate Picking)</p>"
                            >
                                <img
                                    width="200"
                                    height="100"
                                    className="img-responsive"
                                    src="https://img.youtube.com/vi/bBcxaVqYOvg/maxresdefault.jpg"
                                />
                            </a>
 
                            <a
                                className="lesson-yt-video"
                                href="#"
                                data-lg-size="1280-720"
                                data-src="//www.youtube.com/watch?v=5Qc426qgSho&mute=0"
                                data-poster="https://img.youtube.com/vi/5Qc426qgSho/maxresdefault.jpg"
                                data-sub-html="<h4>Picking & Strumming | Guitar Center Lessons</h4><p>How to Use a Guitar Pick (and Basic Strumming Patterns) </p>"
                            >
                                <img
                                    width="200"
                                    height="100"
                                    className="img-responsive"
                                    src="https://img.youtube.com/vi/5Qc426qgSho/maxresdefault.jpg"
                                />
                            </a>

                            <a
                                className="lesson-yt-video"
                                href="#"
                                data-lg-size="1280-720"
                                data-src="//www.youtube.com/watch?v=PuiXqESqm0M&mute=0"
                                data-poster="https://img.youtube.com/vi/PuiXqESqm0M/maxresdefault.jpg"
                                data-sub-html="<h4>Your First Scale | Guitar Center Lessons</h4><p>Learn Your First Scale (Minor Pentatonic)</p>"
                            >
                                <img
                                    width="200"
                                    height="100"
                                    className="img-responsive"
                                    src="https://img.youtube.com/vi/PuiXqESqm0M/maxresdefault.jpg"
                                />
                            </a>


                            <a
                                className="lesson-yt-video"
                                href="#"
                                data-lg-size="1280-720"
                                data-src="//www.youtube.com/watch?v=eNTTiaAEAcc&mute=0"
                                data-poster="https://img.youtube.com/vi/eNTTiaAEAcc/maxresdefault.jpg"
                                data-sub-html="<h4>First Acoustic Guitar | Guitar Center Lessons</h4><p>What to Bring to Your First Acoustic Guitar Lesson</p>"
                            >
                                <img
                                    width="200"
                                    height="100"
                                    className="img-responsive"
                                    src="https://img.youtube.com/vi/eNTTiaAEAcc/maxresdefault.jpg"
                                />
                            </a>
      
                            <a
                                className="lesson-yt-video"
                                href="#"
                                data-lg-size="1280-720"
                                data-src="//www.youtube.com/watch?v=758IE2tuVSY&mute=0"
                                data-poster="https://img.youtube.com/vi/758IE2tuVSY/maxresdefault.jpg"
                                data-sub-html="<h4>Tune Your Acoustic Guitar | Guitar Center Lessons</h4><p>How to Tune the Acoustic Guitar</p>"
                            >
                                <img
                                    width="200"
                                    height="100"
                                    className="img-responsive"
                                    src="https://img.youtube.com/vi/758IE2tuVSY/maxresdefault.jpg"
                                />
                            </a>  

            </LightGallery>
        </div>
    );
}
export default Gallery;