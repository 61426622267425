import './App.css';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from './pages/Main';

function App() {
  return (
    <div className="App" id="v-a">
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact={true} path="/" component={Main} />
      <Route exact path='*' component={Main}></Route>
    </Switch>
    </div>
  );
}

export default App;
