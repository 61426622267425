import React, { useRef, useEffect, useState } from "react";
import LessonsTable from '../../components/LessonsTable';
import './MyLessons.css';



function MyLessons(props){

        return (
          
          <div>
            <LessonsTable />
          </div>
            
        );
    
}

export default MyLessons;