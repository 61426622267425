import React from 'react';
import MyLessons from './MyLessons/MyLessons';

class Main extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

            currentTab: 1
        };
    }

   
   

    render() {
        const { currentTab } = this.state;
       
  
         return ( 
            <div>
                  <MyLessons  /> : 
            </div>
            );
     }
}

export default Main;