import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { Image } from "react-bootstrap";
import item from "../../img/item.jpeg";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  MenuItem,
} from "@mui/material";
import CardImg from "../../img/card_img.png";
import MatButton from "@mui/material/Button";
import { red } from "@mui/material/colors";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { states } from "../../data/data";

export interface OrderItemProps {
  orderNumber: string;
  orderStatus: string;
  name: string;
  subscriptionDuration: string;
  price: number;
  isAutoRenew: boolean;
  nextPaymentDate: string;
  cardDetail: {
    cardType: string;
    holderName: string;
    cardNumber: string;
    expiryDate: string;
  };
  onRenewalClick: Function;
}

const RedColorButton = styled(MatButton)(({ theme }) => ({
  color: theme.palette.getContrastText(red[700]),
  backgroundColor: red[700],
  "&:hover": {
    backgroundColor: red[700],
  },
}));

interface PaymentUpdateForm {
  selectedPaymentType: string;
  billingAddress: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
  };
  creditCard: {
    holderName: "";
    expirationDetail: "";
    creditCardNumber: "";
  };
}
export const OrderItem: React.FC<OrderItemProps> = ({
  name,
  orderNumber,
  orderStatus,
  subscriptionDuration,
  price,
  isAutoRenew,
  nextPaymentDate,
  cardDetail,
  onRenewalClick,
}) => {
  const managePaymentInitialValue: PaymentUpdateForm = {
    selectedPaymentType: "gearCard",
    billingAddress: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
    },
    creditCard: {
      holderName: "",
      expirationDetail: "",
      creditCardNumber: "",
    },
  };

  const managePaySubmitHandler = (
    values: PaymentUpdateForm,
    helper: FormikHelpers<PaymentUpdateForm>
  ) => {
    console.log(values);
  };

  const managePaymentFormik = useFormik({
    initialValues: managePaymentInitialValue,
    onSubmit: managePaySubmitHandler,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    managePaymentFormik.submitForm();
  };

  const { cardType, holderName, cardNumber, expiryDate } = cardDetail;

  const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: red[800],
      "&:hover": {
        backgroundColor: alpha(red[800], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: red[800],
    },
  }));

  return (
    <>
      <div style={styles.mainContainer}>
        <div style={styles.titleContainer}>
          <div style={styles.titleInnerContainer}>
            <span>Order#:</span>
            <span>{orderNumber}</span>
          </div>
        </div>
        <div style={styles.firstSectionContainer}>
          <div style={{ display: "flex" }}>
            <div style={styles.leftContainer}>
              <div style={styles.statusContainer}>
                <div style={{ fontWeight: "bold" }}>Status:</div>
                <span
                  style={{
                    ...styles.statusVal,
                    color: orderStatus === "Active" ? "green" : "red",
                  }}
                >
                  {orderStatus}
                </span>
              </div>
              <div style={styles.subscriptionContainer}>
                <Image src={item} style={styles.itemImg} />
                <div style={{ marginLeft: 10 }}>
                  <div style={{ fontWeight: "bold" }}>{name}</div>
                  <div style={{ marginTop: 5 }}>
                    <span style={{ fontSize: 14 }}>
                      {`28 days Subscription`}
                      <span
                        style={{ fontWeight: "bold", marginLeft: 5 }}
                      >{`$${price}/yr`}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.rightContainer}>
              <div>
                <div style={{ color: "black" }}>
                  <span style={{ fontWeight: "600" }}>Auto Renewal:</span>
                  <span style={{ marginLeft: 5 }}>On</span>
                  <RedSwitch
                    defaultChecked
                    color="warning"
                    checked={isAutoRenew}
                    onChange={() => onRenewalClick()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div style={{ flex: 7 }}>
              <div style={{ display: "flex", fontSize: 14 }}>
                <span style={{ fontWeight: "bold" }}>Next Payment:</span>
                <span style={{ marginLeft: 5 }}>{nextPaymentDate}</span>
              </div>
              {/* <div style={{ display: 'flex', fontSize: 14, marginTop: 5 }}>
                            <span>Expires on:</span>
                            <span style={{ marginLeft: 5, marginRight: 5 }}>
                                {expireDate}
                            </span>
                            <span>
                                {`  (Renews yearly)`}
                            </span>
                        </div> */}
            </div>
            {cardDetail && (
              <div style={{ flex: 3, fontSize: 14 }}>
                <div style={{ fontWeight: "bold" }}>Next Payment:</div>
                <div style={{ display: "grid", marginTop: 10 }}>
                  <span>{cardType} Credit Card</span>
                  <span style={{ marginTop: 3 }}>{holderName}</span>
                  <span style={{ marginTop: 3 }}>{cardNumber}</span>
                  <span style={{ marginTop: 3 }}>{expiryDate}</span>
                  <span
                    style={{
                      marginTop: 3,
                      fontSize: 12,
                      color: "#E7131A",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleClickOpen}
                  >
                    Manage Payment
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "50%",
            minHeight: "70%",
          },
        }}
        fullWidth={true}
      >
        <DialogTitle
          style={{ paddingTop: 10, paddingBottom: 0, fontWeight: "bold" }}
        >
          Update Payment
        </DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
          <hr />
          <Box style={{ marginInline: 20 }}>
            {/* <Box style={{ display: "flex" }}>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="firstName"
                  name="billingAddress.firstName"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="middleName"
                  name="billingAddress.middleName"
                  label="Middle Name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="lastName"
                  name="billingAddress.lastName"
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
            </Box> */}
            <Box style={{ display: "flex", marginTop: 20 }}>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="email"
                  name="billingAddress.email"
                  label="Email Address"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="phoneNumber"
                  name="billingAddress.phoneNumber"
                  label="Mobile Number"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
            </Box>
            <Box style={{ display: "flex", marginTop: 15 }}>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  alt="card-info"
                  src={CardImg}
                />
              </Box>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={managePaymentFormik.values.selectedPaymentType}
                  onChange={(e) => {
                    managePaymentFormik.setFieldValue(
                      "selectedPaymentType",
                      e.target.value
                    );
                  }}
                >
                  <FormControlLabel
                    value="gearCard"
                    control={<Radio />}
                    label="Gear Card"
                  />
                  <FormControlLabel
                    value="creditCard"
                    control={<Radio />}
                    label="Credit Card"
                  />
                </RadioGroup>
                <TextField
                  id="creditCardNumber"
                  name="creditCard.creditCardNumber"
                  label="Credit Card Number"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  onChange={managePaymentFormik.handleChange}
                />
                <TextField
                  id="holderName"
                  name="creditCard.holderName"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  style={{ marginTop: 10 }}
                  onChange={managePaymentFormik.handleChange}
                />
                <TextField
                  id="expirationDetail"
                  name="creditCard.expirationDetail"
                  label="Expiration(MM/YYYY)"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  style={{ marginTop: 10 }}
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
            </Box>
            <Box style={{ display: "flex", marginTop: 15 }}>
              <TextField
                id="address1"
                name="billingAddress.address1"
                label="Address"
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="new-password"
                style={{ flex: 1 }}
                onChange={managePaymentFormik.handleChange}
                // style={{ width: '85%' }}
              />
              <TextField
                id="address2"
                name="billingAddress.address2"
                label="App#"
                variant="outlined"
                type="text"
                autoComplete="new-password"
                style={{ marginLeft: 10, paddingRight: 20 }}
                onChange={managePaymentFormik.handleChange}
              />
            </Box>

            <Box style={{ display: "flex", marginTop: 15 }}>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="city"
                  name="billingAddress.city"
                  label="City"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="new-password"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="state"
                  select
                  name="billingAddress.state"
                  label="State"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  onChange={managePaymentFormik.handleChange}
                >
                  {states.map((s) => (
                    <MenuItem value={s.value}>{s.name}</MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box style={{ flex: 1, paddingRight: 20 }}>
                <TextField
                  id="postalCode"
                  name="billingAddress.postalCode"
                  label="Zip"
                  fullWidth
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  onChange={managePaymentFormik.handleChange}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Box style={{ marginInline: 40 }}>
            <RedColorButton
              variant="contained"
              onClick={handleClose}
              style={{ marginLeft: 10, fontWeight: "bold" }}
            >
              Update Subscription
            </RedColorButton>
            <RedColorButton
              variant="contained"
              onClick={handleClose}
              style={{ marginLeft: 10, fontWeight: "bold" }}
            >
              Cancel
            </RedColorButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  mainContainer: {
    width: 800,
    border: "1px solid black",
    borderRadius: 5,
  },
  titleContainer: {
    display: "flex",
    backgroundColor: "#333333",
  },
  titleInnerContainer: {
    flex: 1,
    color: "white",
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  bodyContainer: {
    padding: 20,
  },
  firstSectionContainer: {
    padding: 20,
  },
  leftContainer: {
    flex: 7,
  },
  rightContainer: {
    flex: 3,
  },
  statusContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  statusVal: {
    color: "green",
    fontWeight: "bold",
    fontSize: 14,
    marginLeft: 5,
  },
  subscriptionContainer: {
    display: "flex",
    marginTop: 10,
  },
  itemImg: {
    height: 70,
    width: 70,
  },
  subValContainer: {
    marginTop: 5,
  },
};
