

import { useState, useEffect } from 'react';
import axios from '../Api/Axios/Axios';
//import axios from 'axios';
import 'regenerator-runtime/runtime';

//axios.defaults.baseURL = process.env.REACT_APP_LESSONS_API_URL;
//axios.defaults.baseURL= 'https://t0e0p8b8rk.execute-api.us-west-2.amazonaws.com/default/v1/lessons/details';

/**
 fixed :
  - no need to JSON.stringify to then immediatly do a JSON.parse
  - don't use export defaults, because default imports are hard to search for
  - axios already support generic request in one parameter, no need to call specialized ones
**/
export const useAxios = (axiosParams) => {
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async (params) => {
        try {
         const result = await axios.request(params);
         setResponse(result.data);
         } catch( error ) {
           setError(error);
         } finally {
           setLoading(false);
         }
      };
        fetchData(axiosParams);
    }, []); // execute once only

    return { response, error, loading };
};

